import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

import useTranslate from '@/utilities/composables/useTranslate';
import useCustomIcon from '@/utilities/composables/useCustomIcon';
import { computed, getCurrentInstance } from 'vue';
import useCurrentRole from '@/utilities/composables/useCurrentRole';


export default /*@__PURE__*/_defineComponent({
  __name: 'Badge',
  props: {
    icon: {},
    text: {}
  },
  emits: ['click'],
  setup(__props: any, { emit: __emit }) {

const _this = getCurrentInstance()?.proxy as any;

const { translate } = useTranslate();
const { iconPath, getCustomIconStyle } = useCustomIcon();
const { isElevated } = useCurrentRole();

const props = __props;

const emit = __emit;

const owner = computed(() => {
  return _this.$store.getters.getMemberById(_this.$store.getters.getWorkspaceOwner?.id);
});

const openPopup = (event: any) => {
  if (!isElevated.value) return;
  emit('click', event);
};

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["badge-label", { 'cursor-default': !_unref(isElevated) }]),
    onClick: openPopup
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("em", {
          key: 0,
          class: "custom-icons w-3 h-3 mr-1",
          style: _normalizeStyle([_unref(getCustomIconStyle)(_unref(iconPath)(`${props.icon}`))])
        }, null, 4))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_unref(translate)(`${props.text}`)), 1)
  ], 2)), [
    [_directive_tooltip, {
      content: _unref(translate)('upgradePlanBadgeTooltip', { spaceOwner: owner.value?.display_name }),
      placement: 'bottom',
      onShow: () => !_ctx.isMobile,
    }]
  ])
}
}

})