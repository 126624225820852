<template>
  <div
    class="popup-header"
    :class="{ 'h-7 items-center': spaceAccessCheck }"
  >
    <div class="popup-title-wrap">
      <em
        v-if="customIcon"
        class="custom-header-icon bg-icon-default"
        :style="getCustomIconStyle(iconPath(customIcon))"
      />
      <div class="popup-title-right">
        <span class="popup-title-text">{{ title }}</span>
        <span
          v-if="description"
          class="popup-description"
          >{{ description }}</span
        >
      </div>
      <div
        v-if="buttonIcon"
        v-tooltip="{ content: buttonTooltip, placement: 'bottom' }"
        class="title-button pt"
        @click="$emit('button-click')"
      >
        <em
          :style="getCustomIconStyle(iconPath(buttonIcon))"
          class="custom-icons copy-header-icon"
        />
      </div>
    </div>
    <EscTooltip @closePopup="handleClose" />
  </div>
</template>

<script>
import EscTooltip from '@/components/widgets/EscTooltip.vue';
import useCustomIcon from '@/utilities/composables/useCustomIcon';

export default {
  name: 'PopupHeader',
  components: {
    EscTooltip,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    customIcon: {
      type: String,
      required: false,
      default: '',
    },
    spaceAccessCheck: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonTooltip: {
      type: String,
      required: false,
      default: '',
    },
    buttonIcon: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['button-click', 'close'],
  setup() {
    const { iconPath, getCustomIconStyle } = useCustomIcon();

    return {
      iconPath,
      getCustomIconStyle,
    };
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.popup-header {
  user-select: none;
  display: flex;
  justify-content: space-between;
  font-family: $primaryFontFamily;
  color: $stLightBlack;
  border-radius: 8px 8px 0px 0px;
  @apply bg-background-strongest
  text-text-default
  border-dividers-default
  pl-6
  pr-6
  pb-0
  pt-6;

  .popup-title-wrap {
    display: flex;
    align-items: center;
    @apply text-lg;

    .popup-title-right {
      display: flex;
      flex-direction: column;

      .popup-title-text {
        @apply font-medium
          not-italic
          text-lg
          text-text-default;
      }
      .popup-description {
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.3px;
        @apply text-text-default;
      }
    }
  }

  .custom-header-icon {
    height: 24px;
    width: 24px;
    margin-right: 15px;
  }

  .close-icon {
    margin: 0;
  }

  .title-button {
    @apply h-[24px] w-[24px] flex justify-center items-center rounded ml-2;

    &:hover {
      @apply bg-icon-bg;
    }
  }
}
</style>
