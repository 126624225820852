<template>
  <div
    :class="['dropdown', position, { 'dropdown-item-selected': selectedItem !== -1 }]"
    :style="{
      width: fixedWidth ? dropdownWidth : 'max-content',
      'min-width': dropdownWidth,
    }"
    @click.stop
    @mousemove="mouseMoveHandle"
  >
    <div
      v-if="title"
      class="popup-title uppercase text-text-default"
    >
      {{ title }}
    </div>
    <input
      v-if="!disabledKeyNavigation"
      :ref="'dropdownInput'"
      v-autofocus
      class="hidden-input"
      data-hidden-input="true"
      readonly="true"
      @keyup.stop="checkKey"
      @keydown.stop="checkDownKey"
    />
    <vue-scroll
      ref="listScroll"
      class="list-dropdown-scroll"
      :ops="{
        scrollPanel: { scrollingX: false },
        rail: { gutterOfSide: '4px', gutterOfEnds: '4px', size: '8px' },
        bar: { onlyShowBarOnScroll: false, minSize: 0.25, size: '8px' },
      }"
    >
      <div
        v-for="(item, index) in filteredItems"
        :key="index"
      >
        <div
          v-if="item.groupLabel"
          class="popup-title uppercase text-text-default"
        >
          {{ item.groupLabel }}
        </div>
        <div
          :class="handleClassesForRow(item)"
          @mouseover="mouseOverHandler(item, index)"
        >
          <div
            v-if="!item.toggleSection"
            :ref="generateRef(index)"
            class="item-row"
            :class="[
              { 'parent-item': item.parent },
              { 'selected-item': selectedItem === index },
              { 'highlighted-item': selectedIndex === index && !item.parent },
              { pt: !item.parent },
              { indent: item.indent },
              { 'top-border-separator': item.parent && index !== 0 },
              { 'disabled-item': item.disabled },
              { 'disabled-item-clickable': item.badge },
              { 'font-medium': item.shouldBeBold },
            ]"
            @click="emitClick(item, index, false, $event)"
          >
            <div
              v-if="!item.checkBox"
              class="dropdown-item-column dropdown-icon-text-wrap usn"
            >
              <EmojiIcon
                v-if="item.iconType === 'emoji'"
                :emoji="item.emojiIcon"
                :classes="'mr-3'"
              />
              <img
                v-if="item.type === 'page' && item.iconType === 'image'"
                :src="generateUrl(item.imageSrc)"
                alt="page icon"
                height="16"
                width="16"
                class="object-cover page-icon flex"
              />
              <div
                v-if="item.type === 'workspace' && item.iconType === 'image'"
                class="sidebar-picture workspace-switcher-picture"
                :style="`background-image: url(${generateUrl(item.imageSrc, 128, 128)})`"
              ></div>

              <ResourceIcon
                v-if="item.additionalIcon"
                class="ml-1 mr-1"
                :src="item.additionalIcon?.src"
                :color="item.additionalIcon?.color"
                :type="item.additionalIcon?.type"
              />

              <ResourceIcon
                v-if="item.type === 'noteTemplate' && item.icon"
                class="mr-2"
                :src="item.icon?.src"
                :color="item.icon?.color"
                :type="item.icon?.type"
              />

              <em
                v-if="item.customIconName"
                :style="[
                  getCustomIconStyle(item.customIconName),
                  { background: item.customIconColor },
                ]"
                class="custom-icons dropdown-item-icon"
                :class="{
                  'bg-inactive-icon': item.disabled,
                  'bg-destructive-text': item.deleteOrCancelType,
                }"
              />
              <div
                v-if="item.noWorkspaceLogo"
                class="sidebar-picture workspace-switcher-picture workspace-switcher-initials"
                :style="{ backgroundColor: item.workspaceColor }"
              >
                <div class="profile-initials">
                  {{ getWorkspaceInitials(item.label) }}
                </div>
              </div>

              <span
                class="dropdown-item-label"
                :class="{
                  'text-inactive-text': item.disabled,
                  'text-destructive-text': item.deleteOrCancelType,
                }"
              >
                {{ item.label }}
              </span>
              <em
                v-if="item.privateItem"
                class="custom-icons private-icon"
                :style="[getCustomIconStyle(iconPath('private-project'))]"
              />
              <span
                v-if="item.meta"
                class="item-meta"
              >
                {{ item.meta }}
              </span>
              <div
                v-if="item.additionalRightIcon"
                v-tooltip="{
                  content: translate(item.additionalRightIconTooltip),
                  placement: 'top',
                }"
                class="box-border p-1 rounded ml-auto mr-2 flex hover:bg-hover-onHover"
                @click.stop="item.additionalRightIconCallback"
              >
                <em
                  :style="getCustomIconStyle(item.additionalRightIcon)"
                  class="custom-icons"
                  :class="{
                    'bg-inactive-icon': item.disabled,
                    'bg-destructive-text': item.deleteOrCancelType,
                  }"
                />
              </div>
              <em
                v-if="item.rightSideIcon"
                :style="[
                  getCustomIconStyle(item.rightSideIcon),
                  { background: item.customIconColor },
                ]"
                class="custom-icons"
                :class="{
                  'bg-inactive-icon': item.disabled,
                  'bg-destructive-text': item.deleteOrCancelType,
                  'ml-auto': !item.additionalRightIcon,
                }"
              />
              <span
                v-if="item.inputLabel"
                class="ml-auto mr-2 text-xs text-deEmphasised-text capitalize"
              >
                {{ translate(item.inputLabel) }}</span
              >
            </div>
            <Badge
              v-if="item.badge"
              :icon="item?.badge?.icon"
              :text="item?.badge?.text"
            />
            <div
              v-if="item.shortcutKey && Array.isArray(item.shortcutKey)"
              class="flex gap-1 ml-1"
            >
              <ShortcutKeyPill
                v-for="(shortcutKey, keyIndex) in item.shortcutKey"
                :key="keyIndex"
                class="dropdown-item-column grow-0"
                :text="shortcutKey.text"
                :background-color="key.background"
                :font-color="key.text"
                :border-color="key.border"
              />
            </div>
            <ShortcutKeyPill
              v-if="item.shortcutKey && !Array.isArray(item.shortcutKey)"
              class="dropdown-item-column grow-0"
              :text="item.shortcutKey.text"
              :background-color="key.background"
              :font-color="key.text"
              :border-color="key.border"
            />
            <div
              v-if="item.toggleButton || item.radioButton || item.checkBox"
              class="dropdown-item-column dropdown-list-toggle"
              :class="{ 'block dropdown-item-column--checkbox': item.checkBox }"
              @click.stop="focusInput"
            >
              <ToggleButton
                v-if="item.toggleButton"
                :ref="getToggleRef(item)"
                :default-value="item.value"
                :disabled="item.disabled"
                @click="handleToggle(item, index)"
              />
              <RadioButton
                v-if="item.radioButton"
                :value="item.selected"
                :name="item.radioName"
                :label="item.id"
                :disabled="item.disabled"
                @change="handleRadioChange(item, index)"
              />
              <StCheckbox
                v-if="item.checkBox"
                :value="item.selected"
                :label="item.label"
                :disabled="item.disabled"
                :tooltip="item.tooltip"
                @changeValue="handleCheckboxChange($event, item)"
              />
            </div>
            <em
              v-if="getShowCheckIcon(item, index)"
              class="custom-icons w-5 h-5 opacity-0 shrink-0"
              :class="getCheckIconClass(item, index)"
              :style="getCustomIconStyle(iconPath('check'))"
            />
          </div>
          <PublicPageListItem
            v-if="item.publicPageItem"
            :url="item.pageUrl || ''"
            :highlighted="selectedIndex === index"
          />
          <ToggleSection
            v-if="item.toggleSection && item.value"
            :highlighted="selectedIndex === index"
            :items="item.value"
            :label="translate('font')"
            @click="handleToggle"
          />
          <div
            v-if="item.publicPageIndex"
            class="public-page-indexing-section"
            :class="{ highlighted: selectedIndex === index }"
          >
            <Badge
              v-if="item.disabled"
              class="w-32"
              icon="lock"
              text="upgradePlan"
              @click="openUpgradePlanPopup"
            />
            <span
              v-else
              class="public-page-item-text usn"
            >
              {{ translate('allowSearchEngines') }}
            </span>
          </div>
        </div>
        <div
          v-if="!!separatorWithTitleAfterIndex && separatorWithTitleAfterIndex.afterIndex === index"
          class="popup-title popup-title--separator uppercase text-text-default"
        >
          {{ separatorWithTitleAfterIndex.title }}
        </div>
      </div>
      <div
        v-if="dropdownFooter"
        class="dropdown-footer"
        @click.stop
      >
        <p
          v-if="additionalFooterTextCondition"
          class="text-text-placeholder"
        >
          {{ translate('additionalFooterText') }}
        </p>
        <p
          class="text-text-placeholder underline cursor-pointer"
          @click="footerClickHandler"
        >
          {{ translate(footerLinkText) }}
        </p>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
import ToggleButton from '@/components/elements/ToggleButton';
import headerMixin from '@/mixins/headerMixin';
import RadioButton from '@/components/elements/RadioButton';
import PublicPageListItem from '@/components/widgets/PublicPageListItem';
import inputDropdown from '@/mixins/inputDropdown';
import ToggleSection from '@/components/widgets/ToggleSection';
import isInViewport from '@/utilities/isInViewport';
import StCheckbox from '@/components/elements/StCheckbox';
import ShortcutKeyPill from '@/components/functional/ShortcutKeyPill';
import defaultShortcutKeyPillStyle from '@/mixins/defaultShortcutKeyPillStyle';
import EmojiIcon from '@/components/functional/EmojiIcon.vue';
import generateSrcUrl from '@/utilities/generateSrcUrl';
import Badge from '@/components/elements/Badge.vue';
import ResourceIcon from '@/components/elements/common/ResourceIcon';
import useShortcutHandlers from '@/utilities/composables/useShortcutHandlers';
import customIconsMixin from '@/mixins/customIconsMixin';
// useCustomIcon composable will break keyboard navigation because of the setup function in inputDropdown.js

export default {
  name: 'ListDropdown',
  components: {
    ToggleButton,
    RadioButton,
    PublicPageListItem,
    ToggleSection,
    StCheckbox,
    ShortcutKeyPill,
    EmojiIcon,
    Badge,
    ResourceIcon,
  },
  mixins: [headerMixin, inputDropdown, defaultShortcutKeyPillStyle, customIconsMixin],
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    position: {
      type: String,
      required: false,
      default: 'bottom-left',
    },
    width: {
      type: Number,
      required: false,
      default: 211,
    },
    fixedWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedItem: {
      type: Number,
      required: false,
      default: -1,
    },
    publicPageIndex: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableScroll: {
      type: Boolean,
      required: false,
      default: false,
    },
    filteredOutKeyCodes: {
      type: Array,
      required: false,
      default: () => [],
    },
    disabledKeyNavigation: {
      type: Boolean,
      required: false,
      default: false,
    },
    separatorWithTitleAfterIndex: {
      type: Object,
      required: false,
      default: () => {},
    },

    groupLabel: {
      type: String,
      required: false,
      default: '',
    },

    disabledItem: {
      type: Boolean,
      required: false,
      default: false,
    },

    closeOnSelect: {
      type: Boolean,
      required: false,
      default: false,
    },

    dropdownFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    'click',
    'hoverDropdownItem',
    'selectedIndexChange',
    'closeEscape',
    'closeDropdown',
    'footerClick',
  ],
  setup() {
    const { quickCardShortcutHandler, quickPageShortcutHandler } = useShortcutHandlers();
    return { quickCardShortcutHandler, quickPageShortcutHandler };
  },
  data() {
    return {
      focusTimeout: null,
      selectedIndex: this.selectedItem,
      scrollToItemTimeout: null,
    };
  },
  computed: {
    dropdownWidth() {
      return `${this.width}px`;
    },
    filteredItems() {
      return this.items.filter((i) => !!i);
    },
    searchResults() {
      return this.filteredItems;
    },
    footerLinkText() {
      return this.translate('manageTemplates');
    },
    additionalFooterText() {
      return this.translate('noTemplates');
    },
    additionalFooterTextCondition() {
      return !this.filteredItems?.length;
    },
  },
  watch: {
    selectedItem(newValue) {
      this.selectedIndex = newValue;
    },
    draggingIsOn(newValue) {
      if (newValue) {
        this.handleEsc();
      }
    },
    selectedIndex(newValue) {
      const el = this.$refs[this.generateRef(newValue)]?.[0];
      if (!el) return;
      const { vertical } = isInViewport(el, this.$refs.listScroll);
      if (!vertical) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    },
  },
  mounted() {
    this.scrollToSelectedItem();
    this.$nextTick(() => {
      this.focusInput();
    });
  },
  unmounted() {
    clearTimeout(this.scrollToItemTimeout);
  },
  methods: {
    // inputDropdown method implementation
    selectSearchResult(item, index, eventParam) {
      if (item.disabled && !item.badge) return;
      let event;
      if (item.checkBox) {
        event = {
          value: !item.selected,
          clickedOutsideCheckbox: false,
        };
      } else if (eventParam) {
        event = eventParam;
      } else {
        event = !item.selected;
      }
      this.emitClick(item, this.selectedIndex, false, event);
      if (this.closeOnSelect) {
        this.handleEsc();
      }
    },
    // inputDropdown method implementation
    toggleItem(item) {
      if (item.disabled) return;
      const event = item.checkBox
        ? {
            value: !item.selected,
            clickedOutsideCheckbox: false,
          }
        : undefined;
      this.handleToggle(item, this.selectedIndex, event);
    },
    focusInput() {
      clearTimeout(this.inputFocusTimeout);
      this.inputFocusTimeout = setTimeout(() => {
        if (this.$refs.dropdownInput) {
          this.$refs.dropdownInput.focus();
          this.$refs.dropdownInput.select();
        }
      }, 160);
    },
    mouseOverHandler(item, index) {
      if (this.isMobile) return;
      if (item.hover) {
        this.emitHover(item);
      }
      if (!this.suspendMouseEnter) {
        this.$emit('selectedIndexChange', index);
        this.selectedIndex = index;
      }
    },
    getToggleRef(item) {
      return `toggleButton-${item.id}`;
    },
    handleToggle(item, index, event) {
      this.emitClick(item, index, true, event);
    },
    handleRadioChange(item, index) {
      this.emitClick(item, index, true);
    },
    handleCheckboxChange(value, item) {
      this.emitClick(item, false, false, value);
    },
    handleClassesForRow(item) {
      return item.separator ? 'row separator' : 'row';
    },
    emitClick(item, index, echo, $event) {
      if (item.parent) {
        $event.preventDefault();
        $event.stopPropagation();
        return;
      }
      if (item.toggleButton && !echo) {
        this.$nextTick(() => {
          const refName = this.getToggleRef(item);
          const [ref] = this.$refs[refName];
          ref.toggleValue();
        });
      }
      if (item.toggleSection) {
        const nextItem = this.getNextItemFromList(item.value);
        this.$emit('click', nextItem, index);
      }
      this.$emit('click', item, index, $event);
    },
    handleEsc() {
      this.$emit('closeEscape');
    },
    emitHover(item) {
      this.$emit('hoverDropdownItem', item);
    },
    generateRef(index) {
      return `selectedItem-${index}`;
    },
    scrollToSelectedItem() {
      if (this.disableScroll) return;
      if (this.selectedItem !== -1 && this.items.length) {
        const element = this.$refs[this.generateRef(this.selectedItem)]?.[0];
        if (!element) return;
        this.scrollToItemTimeout = setTimeout(() => {
          const { vertical } = isInViewport(element, this.$refs.listScroll);
          if (!vertical) {
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }
        }, 150);
      }
    },
    getNextItemFromList(list = []) {
      if (!list.length) return null;
      // Find the selected item in list
      const selectedItemIndex = list.findIndex((i) => i.id === i.selected);
      if (selectedItemIndex === -1) return null;
      // check if the next item is the last item in the list and return the first item
      if (selectedItemIndex === list.length - 1) return list[0];
      // return the next item
      return list[selectedItemIndex + 1];
    },
    generateUrl(src, width = 16, height = 16) {
      return generateSrcUrl(src, width, height, true);
    },
    openUpgradePlanPopup($event) {
      this.$store.dispatch(
        'setShowUpgradePlanPopup',
        this.$constants.featureFlagIds.publicPageIndexing
      );
      this.$emit('closeDropdown', $event);
    },
    getWorkspaceInitials(workspaceName) {
      const firstLetter = workspaceName || '';
      return firstLetter.substring(0, 1).toUpperCase();
    },

    footerClickHandler($event) {
      this.$emit('footerClick', $event);
    },

    getShowCheckIcon(item, index) {
      return (this.selectedItem !== -1 && this.selectedItem === index) || item.showCheck;
    },
    getCheckIconClass(item, index) {
      return [
        { 'opacity-100': this.getShowCheckIcon(item, index) },
        this.selectedItem === index ? 'bg-highlight-default' : 'bg-icon-default',
      ];
    },
  },
};
</script>
<style lang="scss">
@import '../../assets/scss/typography';
@import '../../assets/scss/mixins/index';

.popup-title {
  @include textXsMedium();
  padding: {
    top: $spaceTopSm;
    right: $spaceRightPrimary;
    bottom: $spaceBottomXs;
    left: $spaceLeftPrimary;
  }

  &--separator {
    border-top: 1px solid;
    padding-top: $spaceTopPrimary;
    @apply border-dividers-default;
  }
}

.parent-item {
  @include textXsNormal();
  color: $gray500;
}

.top-border-separator {
  border-top: 1px $gray100 solid;
}

.indent {
  padding-left: 42px !important;
}

.public-page-indexing-section {
  padding: 0px 16px 8px;

  &.highlighted {
    @apply bg-important-hover;
  }

  .public-page-item-text {
    font-size: 12px;
    line-height: 18px;
    @apply text-text-default;
  }
}

.workspace-switcher-picture {
  @apply mr-2;

  width: 20px !important;
  height: 20px !important;
}

.workspace-switcher-initials {
  .profile-initials {
    font-size: 14px;
  }
}

.dropdown-footer {
  @apply flex
      flex-row
      py-[10px]
      px-[15px]
      text-sm
      rounded-lg
      gap-1;
}
</style>
