<template>
  <div
    class="input-wrap"
    :class="{ 'border-inactiveElementBorder': readOnly }"
  >
    <label
      :for="inputId"
      class="input-field-label"
    >
      <span v-if="labelText">
        {{ translate(labelText) }}
      </span>
    </label>
    <em
      v-if="customIcon"
      class="st-input-custom-icon"
      :style="getCustomIconStyle(iconPath(customIcon))"
      @click="focusInput"
    />
    <input
      :id="inputId"
      ref="inputField"
      v-autofocus="focusField"
      :value="value"
      class="input-field"
      :class="[
        toggleClass,
        { 'st-input-icon-show': customIcon },
        { 'bg-background-strongest': readOnly },
      ]"
      :placeholder="translate(placeholderText)"
      :type="inputType"
      :disabled="disabled"
      :readonly="readOnly"
      @input="inputValue = $event.target.value"
      @keydown.enter.prevent.stop="handleEnter"
      @blur="validateOnBlur"
      @keypress="handleKeypress"
      @keyup.up.down.prevent="handleUpDownKeys"
      @keydown.up.down.prevent=""
      @keyup="handleKeyUp"
      @focus="handleFocus"
    />
    <em
      v-if="displayError"
      class="custom-icons input-error-icon usn"
      :style="getCustomIconStyle(iconPath('alert-triangle'))"
    />
    <em
      v-if="clearInput && inputValue"
      class="st-input-custom-icon clear-input-icon"
      :style="getCustomIconStyle(iconPath('x'))"
      @click="handleClearInput"
    />
    <span
      v-if="displayError"
      class="error-message"
    >
      {{ error }}
    </span>
  </div>
</template>

<script>
import { nanoid } from 'nanoid';
import useCustomIcon from '@/utilities/composables/useCustomIcon';

export default {
  name: 'StInput',
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    labelText: {
      type: String,
      required: false,
      default: null,
    },
    placeholderText: {
      type: String,
      required: true,
    },
    inputType: {
      type: String,
      required: true,
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
    inputHeight: {
      type: Number,
      required: false,
      default: 46,
    },
    focusField: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectField: {
      type: Boolean,
      required: false,
      default: false,
    },
    toggleClass: {
      type: String,
      required: false,
      default: '',
    },
    hasDropdown: {
      type: Boolean,
      required: false,
      default: false,
    },
    customIcon: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearInput: {
      type: Boolean,
      required: false,
      default: false,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    'blur',
    'enter-key',
    'enter-ctrl-key',
    'focus',
    'input',
    'keypress',
    'keyup',
    'length-limit',
    'updownkey',
    'validate',
  ],
  setup() {
    const { iconPath, getCustomIconStyle } = useCustomIcon();

    return {
      iconPath,
      getCustomIconStyle,
    };
  },
  data() {
    return {
      displayError: false,
      errorFromBackend: null,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
        if (value === '') {
          this.handleValidation(false);
        }
      },
    },
    inputId() {
      return nanoid(3);
    },
    error() {
      return this.errorFromBackend || this.errorMessage;
    },
    currentLength() {
      return this.inputValue.length;
    },
  },
  watch: {
    focusField(newValue) {
      if (newValue) {
        this.focusInput();
      }
    },
    selectField(newValue) {
      if (newValue) {
        this.selectInput();
      }
    },
    currentLength(newValue, oldValue) {
      if (this.displayError && newValue < oldValue) {
        this.$emit('length-limit', false);
      }
    },
  },
  beforeMount() {
    this.$nextTick(() => {
      if (!this.$refs.inputField) return;
      this.$refs.inputField.style.width = `${this.inputWidth}px`;
      this.$refs.inputField.style.height = `${this.inputHeight}px`;
    });
  },
  methods: {
    handleClearInput() {
      this.inputValue = '';
    },
    focusInput() {
      this.$nextTick(() => {
        this.$refs.inputField.focus();
      });
    },
    blurInput() {
      this.$nextTick(() => {
        this.$refs.inputField.blur();
      });
    },
    selectInput() {
      this.$nextTick(() => {
        this.$refs.inputField.select();
      });
    },
    handleEnter($event) {
      if ($event.metaKey || $event.ctrlKey) {
        this.$emit('enter-ctrl-key', $event);
        return;
      }
      this.$emit('enter-key', $event);
    },
    handleValidation(value, errMessage = '') {
      this.displayError = value;
      if (errMessage) {
        this.errorFromBackend = errMessage;
      } else {
        this.errorFromBackend = null;
      }
    },
    validateOnBlur(e) {
      this.$emit('blur', e);
      if (this.inputValue === '') {
        return;
      }
      this.$emit('validate', e);
    },
    handleKeypress($event) {
      if (this.hasDropdown) {
        this.$emit('keypress', $event);
      }
    },
    handleUpDownKeys($event) {
      if (this.hasDropdown) {
        this.$emit('updownkey', $event);
      }
    },
    handleFocus(e) {
      this.$emit('focus', e);
    },
    select() {
      this.$refs.inputField.select();
    },
    handleKeyUp($event) {
      this.$emit('keyup', $event);
    },
  },
};
</script>
