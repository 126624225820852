import { defineComponent as _defineComponent } from 'vue'
import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  ref: 'hiddenInput',
  readonly: "true",
  class: "hidden-input",
  "data-hidden-input": "true"
}
const _hoisted_2 = { class: "flex flex-col mb-6 gap-y-2" }

import { onKeyStroke } from '@vueuse/core';
import { getCurrentInstance, onMounted, onUnmounted, computed } from 'vue';
import useTranslate from '@/utilities/composables/useTranslate';
import ButtonCustom from '@/components/elements/ButtonCustom.vue';
import PopupHeader from '@/components/elements/PopupHeader.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ConfirmationModal',
  props: {
    title: {},
    headerIcon: {},
    description: {},
    widthClass: { default: 'w-96' },
    showConfirmButtons: { type: Boolean, default: true },
    buttonWrapClass: {},
    buttonClasses: { default: 'flex-1' },
    confirmButtonText: {},
    confirmButtonType: { default: 'attention' },
    confirmButtonClass: {},
    confirmButtonDisabled: { type: Boolean },
    confirmButtonVariation: {},
    confirmButtonIcon: {},
    confirmButtonIconColor: {},
    additionalConfirmButtonText: {},
    additionalConfirmButtonIcon: {},
    additionalConfirmButtonIconColor: {},
    headerButtonIcon: {},
    headerButtonTooltip: {},
    cancelButtonText: {},
    cancelButtonType: { default: 'secondary' }
  },
  emits: ['cancel', 'confirm', 'additional-confirm', 'header-button-click'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const descriptionArr = computed((): string[] => {
  if (!props.description) return [];
  return Array.isArray(props.description) ? props.description : [props.description];
});

const _this = getCurrentInstance()!.proxy as any;
const { translate } = useTranslate();
const emit = __emit;
const isPublicPageApp = computed(() => process.env.VUE_APP_ID === 'public');

// handle escape key to close confirmation modal
onKeyStroke((e: KeyboardEvent) => {
  if (e.key === 'Escape') {
    e.preventDefault();
    e.stopPropagation();
    emit('cancel');
  }

  if (e.key === 'Enter') {
    e.preventDefault();
    e.stopPropagation();
    emit('confirm');
  }
});

onMounted(() => {
  focusHiddenInput();
  if (!isPublicPageApp.value) {
    _this.$store.commit('setConfirmationModal', true);
  }
});

onUnmounted(() => {
  if (!isPublicPageApp.value) {
    _this.$store.commit('setConfirmationModal', false);
  }
});

const focusHiddenInput = () => {
  if (_this.$refs.hiddenInput) {
    _this.$nextTick(() => _this.$refs.hiddenInput?.focus());
  }
};

return (_ctx: any,_cache: any) => {
  const _directive_autofocus = _resolveDirective("autofocus")!

  return (_openBlock(), _createElementBlock("div", {
    tabindex: "0",
    class: "modal double-check-modal",
    onKeydown: _cache[6] || (_cache[6] = _withKeys(_withModifiers(($event: any) => (emit('cancel')), ["prevent","stop"]), ["esc"])),
    onMousedown: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (emit('cancel')), ["self"]))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal-container border border-solid border-important-border rounded-xl p-6", _ctx.widthClass]),
      onClick: focusHiddenInput,
      onMousedown: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"]))
    }, [
      _withDirectives(_createElementVNode("input", _hoisted_1, null, 512), [
        [_directive_autofocus]
      ]),
      _cache[10] || (_cache[10] = _createTextVNode()),
      _createVNode(PopupHeader, {
        title: _ctx.title,
        class: _normalizeClass('p-0 pb-6'),
        "custom-icon": _ctx.headerIcon,
        "button-icon": _ctx.headerButtonIcon,
        "button-tooltip": _ctx.headerButtonTooltip,
        onClose: _cache[0] || (_cache[0] = ($event: any) => (emit('cancel'))),
        onButtonClick: _cache[1] || (_cache[1] = ($event: any) => (emit('header-button-click')))
      }, null, 8, ["title", "custom-icon", "button-icon", "button-tooltip"]),
      _cache[11] || (_cache[11] = _createTextVNode()),
      _renderSlot(_ctx.$slots, "content", {}, () => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(descriptionArr.value, (d, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: i,
              class: "text-sm text-text-default select-text"
            }, _toDisplayString(d), 1))
          }), 128))
        ])
      ]),
      _cache[12] || (_cache[12] = _createTextVNode()),
      _renderSlot(_ctx.$slots, "footer", {}, () => [
        (_ctx.showConfirmButtons)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["flex", _ctx.buttonWrapClass])
            }, [
              _createVNode(ButtonCustom, {
                class: _normalizeClass(["cancel-button rounded text-sm text-text-default h-8", _ctx.buttonClasses]),
                text: _ctx.cancelButtonText || _unref(translate)('cancel'),
                type: _ctx.cancelButtonType,
                onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (emit('cancel')), ["stop"]))
              }, null, 8, ["class", "text", "type"]),
              _cache[8] || (_cache[8] = _createTextVNode()),
              _createVNode(ButtonCustom, {
                class: _normalizeClass(["confirm-button rounded text-sm h-8", `${_ctx.buttonClasses} ${_ctx.confirmButtonClass}`]),
                type: _ctx.confirmButtonType,
                text: _ctx.confirmButtonText || _unref(translate)('confirm'),
                variation: _ctx.confirmButtonVariation,
                "custom-icon-name": _ctx.confirmButtonIcon,
                "custom-icon-color": _ctx.confirmButtonIconColor,
                "custom-icon-size": '16px',
                disabled: _ctx.confirmButtonDisabled,
                onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (emit('confirm')), ["stop"]))
              }, null, 8, ["class", "type", "text", "variation", "custom-icon-name", "custom-icon-color", "disabled"]),
              _cache[9] || (_cache[9] = _createTextVNode()),
              (_ctx.additionalConfirmButtonText)
                ? (_openBlock(), _createBlock(ButtonCustom, {
                    key: 0,
                    class: _normalizeClass(["confirm-button rounded text-sm h-8", `${_ctx.buttonClasses} ${_ctx.confirmButtonClass} ml-2`]),
                    type: _ctx.confirmButtonType,
                    text: _ctx.additionalConfirmButtonText || _unref(translate)('confirm'),
                    variation: _ctx.confirmButtonVariation,
                    "custom-icon-name": _ctx.additionalConfirmButtonIcon,
                    "custom-icon-color": _ctx.additionalConfirmButtonIconColor,
                    "custom-icon-size": '16px',
                    disabled: _ctx.confirmButtonDisabled,
                    onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (emit('additional-confirm')), ["stop"]))
                  }, null, 8, ["class", "type", "text", "variation", "custom-icon-name", "custom-icon-color", "disabled"]))
                : _createCommentVNode("", true)
            ], 2))
          : _createCommentVNode("", true)
      ])
    ], 34)
  ], 32))
}
}

})